var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ec-message-content", {
    staticClass: "message-detail",
    attrs: { "message-content": _vm.messageContent },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }