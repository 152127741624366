<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-04-17 19:00:37.804 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2024-04-12 19:26:28 -->

<template>
    <div class="message-content">
        <h3 class="title">
            {{ messageContent.title }}
        </h3>
        <p class="date">
            {{ messageContent.timeCreated | formatTime }}
        </p>
        <p class="content">
            {{ messageContent.content }}
        </p>
    </div>
</template>

<script type="text/babel">
import moment from 'moment';

export default {
    name: 'EcMessageContent',

    filters: {
        formatTime(time) {
            return time ? moment(time).format('DD/MM/YYYY HH:mm') : '';
        }
    },

    props: {
        messageContent: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .message-content {
        .title {
            margin: 0;
            line-height: 18px;
            color: #333;
            font-size: 14px;
            font-weight: bold;
        }

        .date {
            margin: 5px 0;
            line-height: 16px;
            font-size: 12px;
            color: #999;
        }

        .content {
            margin: 0;
            line-height: 16px;
            font-size: 12px;
            color: rgba(51, 51, 51, 1);
        }
    }

</style>
