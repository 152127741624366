var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-content" }, [
    _c("h3", { staticClass: "title" }, [
      _vm._v("\n        " + _vm._s(_vm.messageContent.title) + "\n    "),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "date" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm._f("formatTime")(_vm.messageContent.timeCreated)) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "content" }, [
      _vm._v("\n        " + _vm._s(_vm.messageContent.content) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }