<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-04-21 10:53:37.224 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <ec-message-content
        class="message-detail"
        :message-content="messageContent"
    />
</template>

<script type="text/babel">
import EcMessageContent from 'easycash/common/component/ec-message-content';
import {messageDetail} from 'easycash/common/mixin/message-manage';

const {asyncData, ...rest} = messageDetail;

export default {
    name: 'MessageDetail',

    title() {
        return this.$t('webview.message.title');
    },

    components: {
        EcMessageContent
    },

    mixins: [rest],

    asyncData
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.message-detail {
    padding: 25px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    ::v-deep {
        .title,
        .date,
        .content {
            line-height: 1.3;
        }

        .date {
            order: 3;
            margin-top: 16px;
        }

        .content {
            margin-top: 8px;
        }
    }
}
</style>
